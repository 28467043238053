@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.md-subheader {
  font-size: 1.2rem; }

.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0; }

.md-checkbox {
  margin: 22px 16px 16px 0px !important; }

.period {
  margin-top: 16px;
  margin-bottom: 16px; }

.no-underline.md-field:before {
  visibility: hidden !important; }

.no-underline.md-field:after {
  visibility: hidden !important; }
