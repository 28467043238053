@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.one-detention {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  .one-detention:first-child {
    padding-top: 1.5rem; }

.meetings {
  min-width: 150px; }
  .meetings ion-icon {
    font-size: 1.2rem;
    cursor: pointer;
    color: #66ccff; }
