@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.sidebar {
  height: 100vh; }
  .sidebar .vs-sidebar-content {
    z-index: 2 !important; }
  .sidebar .clickicon {
    width: 260px; }
  .sidebar .clickiconreduced {
    width: 50px; }
