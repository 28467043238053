@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important; }

.no-underline.md-field:after {
  visibility: hidden !important; }

.follow-modal .md-list-item {
  z-index: 10002; }
