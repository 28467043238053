@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-file-upload--dropzone {
  background-color: #e9fde8;
  padding: 0 2rem;
  overflow: hidden; }

.btn-text {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0; }
  .btn-text + .btn-text {
    margin-left: 1.5rem; }
  .btn-text--primary {
    color: #33cc00; }
    .btn-text--primary:hover {
      opacity: 0.6; }
  .btn-text--secondary {
    color: #fff; }

@media (max-width: 576px) {
  .vuestic-file-upload--dropzone {
    padding: 0 1rem; } }
