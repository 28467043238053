@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.md-subheader {
  font-size: 1.2rem; }

.horaire .md-input {
  max-width: 120px; }
