@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-date-picker-container:not(:last-child) {
  margin-right: 1.08rem; }

.vuestic-date-picker-container .btn-today {
  height: 25px;
  width: 25px;
  border: 1px solid #737373;
  border-top-left-radius: 4px; }

input.vuestic-date-picker {
  font-size: 1rem; }

.flatpickr-calendar {
  width: 15.5rem !important;
  background-color: #333333;
  border-radius: 0;
  box-shadow: none; }
  .flatpickr-calendar.arrowTop:before {
    content: none;
    border-bottom-color: #333333; }
  .flatpickr-calendar.arrowTop:after {
    content: none;
    border-bottom-color: #333333; }
  .flatpickr-calendar.arrowBottom:before {
    content: none;
    border-top-color: #333333; }
  .flatpickr-calendar.arrowBottom:after {
    content: none;
    border-top-color: #333333; }
  .flatpickr-calendar .flatpickr-days {
    width: 15.5rem !important;
    padding: 0 0.5rem 0.5rem;
    display: block; }
    .flatpickr-calendar .flatpickr-days .dayContainer {
      width: 14.875rem;
      max-width: 14.875rem;
      min-width: 14.875rem;
      margin: -0.1875rem; }
      .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day {
        height: 1.75rem;
        line-height: 1.7;
        font-size: 1rem;
        flex: 0 0 1.75rem;
        margin: 0.1875rem;
        border: none;
        color: #fff; }
        .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.today {
          background-color: #404040; }
        .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day:hover {
          background-color: #4d4d4d; }
        .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.selected {
          background-color: #66ccff; }
        .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.nextMonthDay, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.prevMonthDay {
          color: #999999; }
        .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.disabled {
          color: #999999; }
          .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.disabled:hover {
            background-color: inherit; }
        .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.endRange, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.inRange {
          border-radius: 2rem;
          box-shadow: none; }
        .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.endRange {
          background-color: #66ccff; }
        .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.inRange {
          background-color: #161616; }
  .flatpickr-calendar .flatpickr-months {
    height: 2.625rem;
    border-bottom: solid 0.125rem #161616; }
    .flatpickr-calendar .flatpickr-months .flatpickr-month {
      height: 100%; }
      .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month {
        padding-top: 0.625rem; }
        .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months {
          cursor: pointer;
          color: #fff;
          outline: none;
          overflow: hidden;
          text-indent: 0.01px;
          text-overflow: "";
          background: url("https://www.freeiconspng.com/uploads/white-down-arrow-png-2.png") no-repeat right 2px bottom 5px;
          background-size: auto;
          background-size: 13px;
          background-color: #333333;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          /* width: 100%; */
          font-size: 0.9rem;
          height: 1.71rem;
          padding: 0.1125rem 0.1125rem 0.05625rem;
          padding-right: 0.1125rem;
          padding-right: 18px;
          border: none;
          line-height: 1.6;
          -webkit-box-shadow: none;
          box-shadow: none;
          text-align: right; }
          .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
            background-color: #4d4d4d; }
        .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-month {
          font-size: 1rem;
          font-weight: inherit;
          color: #fff; }
        .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-year {
          font-size: 1rem; }
        .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .numInput.cur-year {
          color: #fff; }
        .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowUp:hover::after {
          border-bottom-color: #66ccff; }
        .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowUp::after {
          border-bottom-color: #fff; }
        .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowDown:hover::after {
          border-top-color: #66ccff; }
        .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowDown::after {
          border-top-color: #fff; }
    .flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
    .flatpickr-calendar .flatpickr-months .flatpickr-next-month {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.625rem;
      width: 1.625rem;
      padding: 0;
      color: #fff; }
      .flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover,
      .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover {
        color: #66ccff; }
  .flatpickr-calendar .flatpickr-weekdays {
    padding: 0 0.5rem; }
    .flatpickr-calendar .flatpickr-weekdays .flatpickr-weekdaycontainer {
      justify-content: space-between; }
      .flatpickr-calendar .flatpickr-weekdays .flatpickr-weekdaycontainer .flatpickr-weekday {
        letter-spacing: 0.0625rem;
        flex: 0 0 1.75rem;
        color: gray;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.6875rem; }
  .flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weekday {
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    color: gray;
    font-weight: bold;
    font-size: 0.6875rem; }
  .flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks {
    box-shadow: 2px 0 0 #161616;
    padding-bottom: 0.5rem;
    margin-top: -0.375rem; }
    .flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks .flatpickr-day {
      height: 1.75rem;
      line-height: 1.7;
      font-size: 1rem;
      flex: 0 0 1.75rem;
      margin-top: 0.375rem;
      color: #999999; }
  .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    border-top: solid 0.125rem #161616; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time :hover {
      background-color: #4d4d4d; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper:hover {
      background-color: #4d4d4d; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .numInput {
      color: #fff; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .arrowUp::after {
      border-bottom-color: #fff; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .arrowUp:hover::after {
      border-bottom-color: #66ccff; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .arrowDown::after {
      border-top-color: #fff; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .arrowDown:hover::after {
      border-top-color: #66ccff; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .flatpickr-time-separator {
      color: #fff; }
    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .flatpickr-am-pm {
      color: #fff; }
      .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .flatpickr-am-pm:hover {
        background-color: #4d4d4d; }
  .flatpickr-calendar .shortcut-buttons-flatpickr-button {
    color: white;
    border: none;
    background-color: #333333;
    border-radius: 5px;
    margin-bottom: 5px; }
    .flatpickr-calendar .shortcut-buttons-flatpickr-button:hover {
      background-color: #4d4d4d; }
