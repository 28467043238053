@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.day {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid white; }
  .day:hover {
    border: 1px solid black; }

.room-square-total {
  border-radius: 20%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px; }
  .room-square-total.square-green {
    background-color: #c6f1b8; }
  .room-square-total.square-red {
    background-color: #f1bdbe; }

.room-circle-antenna {
  border-radius: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px; }
  .room-circle-antenna.circle-green {
    background-color: #c6f1b8; }
  .room-circle-antenna.circle-orange {
    background-color: #fce5c7; }
