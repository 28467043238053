@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.alert {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .alert .badge {
    margin-right: 0.63rem; }
  .alert.with-close {
    padding-right: 3.125rem; }
  .alert .alert-close {
    position: absolute;
    right: 20px;
    font-size: 0.9rem;
    top: calc(50% - 0.9rem/ 2);
    cursor: pointer; }

.alert-success {
  box-shadow: 0px 4px 9.6px 0.4px rgba(79, 206, 145, 0.5); }
  .alert-success .alert-close {
    color: #33cc00; }

.alert-warning {
  box-shadow: 0px 4px 9.6px 0.4px rgba(206, 178, 79, 0.5); }
  .alert-warning .alert-close {
    color: #f6a336; }

.alert-danger {
  box-shadow: 0px 4px 9.6px 0.4px rgba(206, 79, 79, 0.5); }
  .alert-danger .alert-close {
    color: #cc1417; }

.alert-info {
  box-shadow: 0px 4px 9.6px 0.4px rgba(79, 142, 206, 0.5); }
  .alert-info .alert-close {
    color: #4ab2e3; }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s; }

.fade-enter, .fade-leave-to {
  opacity: 0; }

@media (max-width: 767.98px) {
  .alert {
    flex-direction: column; } }
