@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.search-box ion-icon {
  font-size: 1.6rem; }

.search-box .sbx-custom {
  display: inline-block;
  position: relative;
  width: 30vw;
  height: 35px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px; }
  .search-box .sbx-custom .sbx-custom__wrapper {
    width: 100%;
    height: 100%;
    vertical-align: middle; }
  .search-box .sbx-custom .sbx-custom__input {
    display: inline-block;
    border: 0;
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px #303030;
    background: aliceblue;
    padding-right: 25px;
    padding-left: 9px;
    width: 100%;
    height: 100%;
    vertical-align: middle; }
