/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */

// Colors //
// gris dérivés du gris de la brochure
$gray-light: #919191 !default;
$light-gray: #f5f1f5 !default;
$lighter-gray: #c6bec2 !default;
$charcoal: #35353a !default;
$charcoal-10: rgba(55, 55, 58, 0.15) !default;
$darkest-gray: #171114 !default;
$almost-black: #161616 !default;
// verts dérivés du logo RGB
$vue-green: hsl(105, 100%, 40%) !default;
$vue-light-green: hsl(146, 71%, 92%) !default;
$vue-green-light: hsl(106, 100%, 65%) !default;
$vue-green-dark: #009a00 !default;


$ulysse-blue: rgb(102, 204, 255) !default;
$ulysse-blue-light: #a3e0ff !default;
$ulysse-blue-dark: #2e517b !default;
// Thème de eveler (logo + triangles + gris brochure)
$ev-gray: #606066 !default;
$ev-green: #abca3c !default;
$ev-green-light: #81be56 !default;
$ev-green-dark: #688a2e !default;
$ev-red: #cc1417 !default;
$ev-red-dark: #930000 !default;
$ev-red-light: #ff5541 !default;
$invoice-error-red: #f74633 !default;
$ev-red-lightest: #ff9f9f !default;
$light-green: #e7ffb6 !default;
// $light-green: #c8f9c5;   //VERT DE VUESTIC
$lighter-green: #e9fde8 !default; //VERT DE VUESTIC
$light-blue: #dcf1ff !default;
$light-yellow: #fff1c8 !default;
$light-pink: #ffcece !default;
$vue-darkest-blue: #34495e !default;
$vue-light-gray: #a0a5aa !default;
$vue-light-blue: #9aa4af !default;
$vue-lightest-blue: rgb(214, 241, 255) !default;
$vue-lightest-blue-rbg: 214, 241, 255 !default;
$vue-darkest-blue-transparent: hsla(210, 29%, 29%, 0.5) !default;
$vue-turquoise: #dbf5f9 !default;
$ev-text-pp1: $ev-red-light !default;
$ev-text-pp2: $vue-darkest-blue !default;
$white: #fff !default;
$theme-red: #e34a4a !default;
$theme-orange: #f6a336 !default;
$theme-green: $vue-green !default;
$theme-blue: #4ab2e3 !default;
$theme-violet: #db76df !default;
$chart-violet: #bc75f4 !default;
$chart-turquoise: #1ec6b8 !default;
$theme-pale: #c8c8c8 !default;
$brand-primary: $ulysse-blue !default;
$brand-danger: $ev-red !default;
$brand-warning: $theme-orange !default;
$brand-info: $theme-blue !default;
$brand-success: $vue-green !default;
$enedis-green: #93c90e !default;
$enedis-green-lightest: #eafac1 !default;
$edf-orange: #fd5c0e !default;
$edf-orange-lightest: #fed7c3 !default;
$gouv-gray: #8f8f8f !default;
$gouv-gray-lightest: #e3e3e3 !default;
$light-gray2: #eff4f5 !default;
$dark-gray: #282828 !default;
$gray: #adb3b9 !default;
$violet: #db76df !default;
$dark-blue: #0045b6 !default;
$breadcrumbs-gray: #b4b4b4 !default;
$text-gray: $breadcrumbs-gray !default;
$colors-map: (
  brand-danger: $brand-danger,
  brand-primary: $brand-primary,
  brand-info: $brand-info,
  brand-success: $brand-success,
  brand-warning: $brand-warning,
  black: black,
  white: white,
  lighter-gray: $lighter-gray,
) !default;
$theme-colors: (
  primary: $brand-primary,
  secondary: $white,
  success: $brand-success,
  info: $brand-info,
  warning: $brand-warning,
  danger: $brand-danger,
  light: $light-gray2,
  dark: $almost-black,
  pale: $theme-pale,
) !default;
//Breadcrumbs
$breadcrumbs-height: 36px !default;
$breadcrumbs-arrow-font: 0.7rem !default;
$breadcrumbs-arrow-content: '\f054' !default;
// Viewchart
// définition des couleurs de la courbe de charge
$viewchart-chartcolor-default: $brand-primary !default;
$viewchart-chartcolor-hygro: grey !default;
$viewchart-chartcolor-temperature: #7e9eb5 !default;
$viewchart-chartcolor-pressure: #d5c945 !default;
$viewchart-chartcolor-gas: #e5b232 !default;
$viewchart-chartcolor-volume: #46c3ce !default;
$viewchart-chartcolor-unkown-metric: #f98f04 !default;
$viewchart-chartcolor-pointe: #b54566 !default;
$viewchart-chartcolor-pointe-er: #555555 !default;
$viewchart-chartcolor-pleine: #46c746 !default;
$viewchart-chartcolor-pleine-er: #888888 !default;
$viewchart-chartcolor-pleine-ete: #68f968 !default;
$viewchart-chartcolor-pleine-ete-er: #aaaaaa !default;
$viewchart-chartcolor-ja: #9aff60 !default;
$viewchart-chartcolor-ja-er: #dddddd !default;
$viewchart-chartcolor-compared: $theme-blue !default;
$viewchart-chartcolor-creuse: #b9fdb9 !default;
$viewchart-chartcolor-creuse-er: #999999 !default;
$viewchart-chartcolor-creuse-ete: #daffda !default;
$viewchart-chartcolor-creuse-ete-er: #bbbbbb !default;
$viewchart-chartcolor-day-or-more-freq: $viewchart-chartcolor-creuse !default;
$viewchart-chartcolor-outage: $brand-danger !default; // Coupure
$viewchart-chartcolor-incomplete-data-color: lighten(
  desaturate($theme-red, 25%),
  10%
) !default; // Données incomplète (genre 3 sur 5)
$viewchart-chartcolor-excess: red !default; // Dépassements
$viewchart-chartcolor-stroke: hsla(0, 0, 50%, 0.75) !default;
$viewchart-chartcolor-axis: hsla(0, 0, 75%, 0.5) !default;
$viewchart-chartcolor-hover: black !default;
$viewchart-chartcolor-week-end: #eff4f5 !default;
$viewchart-chartcolor-no-data: #ffffff !default; // Colorisation de l'axe des X là où il n'y a pas de données
$viewchart-chartcolor-difference: red !default; // Colorisation de l'axe des X avec une différence entre brute et importée
$viewchart-chartcolor-tanphi: rgb(180, 0, 120) !default;
$viewchart-chartcolor-ps: #b400b4 !default;
$viewchart-chartcolor-co2: #582900 !default;
$viewchart-chartcolor-temp: #FF0A3F !default;
$viewchart-chartcolor-rainfall: #0099cc !default;
$viewchart-chartcolor-pmax: $brand-primary !default;
// Layout //
$body-bg: $light-gray !default;
$top-nav-bg: $charcoal !default;
$body-color: $vue-darkest-blue !default;
$layout-padding: 1.25rem !default;
$layout-padding-right: 44px !default;
$top-nav-height: 72px !default;
$nav-padding-left: $layout-padding !default;
$nav-padding-right: $layout-padding-right !default;
$navbar-brand-container-left: 75px !default;
$sidebar-bg: $gray-light !default;
$sidebar-expand-bg: $white !default;
$sidebar-tree-color: $almost-black !default;
$sidebar-width: 275px !default;
$sidebar-top: calc(#{$top-nav-height}) !default;
$sidebar-left: 0 !default;
$content-wrap-ml: calc(#{$sidebar-width} + #{$layout-padding}) !default;
$content-wrap-pr: $layout-padding !default;
$content-wrap-pt: $layout-padding !default;
$content-wrap-pb: $layout-padding !default;
$gray-box-shadow: 2px 4px 9.6px 0.4px rgba($gray, 0.5) !default;
$greeny-box-shadow: 0 4px 9.6px 0.4px rgba($ulysse-blue-dark, 0.5) !default;
$success-box-shadow: 0 4px 9.6px 0.4px rgba($brand-success, 0.5) !default;
$danger-box-shadow: 0 4px 9.6px 0.4px rgba($brand-danger, 0.5) !default;
$info-box-shadow: 0 4px 9.6px 0.4px rgba($brand-info, 0.5) !default;
$warning-box-shadow: 0 4px 9.6px 0.4px rgba($brand-warning, 0.5) !default;
$min-z-index: -1000 !default;
// Chat
$chat-body-min-height: 4rem !default;
$chat-body-max-height: 28rem !default;
$chat-body-mb: 1.5rem !default;
$chat-message-mb: 0.625rem !default;
$chat-message-py: 0.657rem !default;
$chat-message-px: 1.375rem !default;
$chat-message-br: 0.875rem !default;
//Auth
$auth-wallpaper-ivuestic-h: 12rem !default;
$auth-wallpaper-oblique-line: $dark-gray !default;
//Mobile Layout
$top-mobile-nav-height: 6rem !default;
$layout-mobile-padding: 0.75rem !default;
$layout-mobile-padding-right: 0.375rem !default;
$sidebar-mobile-width: 100vw !default;
$sidebar-mobile-top: $top-mobile-nav-height !default;
$sidebar-mobile-left: 0 !default;
$content-mobile-wrap-margin: 1.125rem !default;
$sidebar-mobile-z-index: 1000 !default;
$content-mobile-wrap-pl: 1rem !default;
$content-mobile-wrap-pr: 1rem !default;
$content-mobile-wrap-pt: 1rem !default;
$content-mobile-wrap-pb: 2rem !default;
$content-mobile-wrap: $content-mobile-wrap-pt $content-mobile-wrap-pr
  $content-mobile-wrap-pb $content-mobile-wrap-pl !default;
$nav-mobile-padding-h: 0.467rem !default;
$nav-mobile-pt: 1.6rem !default;
$nav-mobile-pb: 0.734rem !default;
$nav-mobile-brand-width: 4rem !default;
$nav-mobile-brand-top: 0.875rem !default;
$nav-mobile-brand-left: calc(50% - #{$nav-mobile-brand-width}) !default;
$droppdown-mobile-mp: 1.9375rem !default;
$dropdown-mobile-show-b: 2rem !default;
//Auth mobile
$auth-mobile-nav-ivuestic-h: 1.5rem !default;
$auth-mobile-main-h: calc(100% - #{$top-mobile-nav-height}) !default;
$auth-content-padding-t: 2.875rem !default;
// Typography
// -------------------------
//$text-color:            $gray !default;
$font-family-sans-serif: 'Open Sans', sans-serif !default;
$font-family-titles: 'Montserrat', sans-serif !default;
$font-size-root: 16px !default;
$font-size-base: 0.9rem !default;
$font-size-larger: 1.2rem !default;
$font-size-large: 1.5rem !default;
$font-size-mini: 0.8rem !default;
$font-weight-bold: 700 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-normal: 400 !default;
$font-weight-thin: 300 !default;
$font-weight-base: $font-weight-thin !default;
$font-size-smaller: 85% !default;
$font-size-h1: 1.6rem !default;
$font-size-h2: 1.4rem !default;
$font-size-h3: 1.2rem !default;
$font-size-h4: 1rem !default;
$headings-line-height: 1 !default;
$blockquote-border-color: $vue-green !default;
$blockquote-small-color: $gray-light !default;
$blockquote-border-width: 0.375rem !default;
$blockquote-font-size: 1.5rem !default;
//Navbar
$navbar-dd-item-height: 48px !default;
//Sidebar
$sidebar-link-height: 56px !default;
$sidebar-submenu-link-height: 48px !default;
$sidebar-link-pl: 25px !default;
$sidebar-submenu-link-pl: 74px !default;
$sidebar-link-active-bg: $charcoal !default;
$sidebar-expand-icon-color: $vue-green !default;
$sidebar-arrow-right: 16px !default;
$sidebar-menu-item-icon-size: 19px !default;
$sidebar-menu-item-icon-color: $vue-green !default;
$sidebar-viewport-height: calc(100vh - #{$top-mobile-nav-height}) !default;
$sidebar-mobile-viewport-height: calc(
  100vh - #{$top-mobile-nav-height}
) !default;
$sidebar-hidden-top: -150px !default;
$sidebar-hidden-top-mobile: $sidebar-mobile-top !default;
$sidebar-hidden-height-mobile: 0 !default;
$sidebar-box-shadow: 0px 8px 14.72px 1.28px rgba(#65a977, 0.3) !default;
$sidebar-font-color: $charcoal !default;

//Widgets
$widget-bg: $white !default;
$widget-padding: 1.5625rem !default;
$widget-larger-padding: 45px !default;
$widget-border-radius: 5px !default;
$widget-box-shadow: 0px 4px 70px -18px rgba(112, 112, 112, 1) !default;
$widget-box-inside-shadow: inset 0px 4px 70px -18px rgba(112, 112, 112, 1) !default;
$widget-box-shadow-small-padding: 0px 20px 46px -13px rgba(112, 112, 112, 0.7) !default;
$widget-danger-shadow: 0px 4px 70px -16px $brand-danger !default;
$widget-info-shadow: 0px 4px 70px -16px $brand-info !default;
$widget-success-shadow: 0px 4px 70px -16px $brand-success !default;
$widget-warning-shadow: 0px 4px 70px -16px $brand-warning !default;
$widget-viewport-height: $sidebar-viewport-height !default;
$widget-header-border: 2px solid $light-gray !default;
$widget-header-height: 55px !default;
$widget-body-no-header-height: 100% !default;
$widget-body-with-header-height: calc(100% - #{$widget-header-height}) !default;
$widget-mb: $layout-padding !default;
$info-widget-border: 0.5rem solid $brand-primary !default;

// Classe banner-title
$banner-title-color: $vue-green !default;

// Links
$link-color: $brand-primary !default;
$link-hover-color: lighten($link-color, 10%) !default;
$link-hover-decoration: none !default;
//Buttons
$input-btn-border-width: 0px !default;
$btn-line-height: 1.25 !default;
$btn-line-height-sm: $btn-line-height !default;
$btn-padding-x: 3.9rem !default;
$btn-padding-y: 1.1rem !default;
$btn-padding-y-sm: 0.7rem !default;
$btn-padding-x-sm: 2.5rem !default;
$btn-padding-y-micro: 0.625rem !default;
$btn-padding-x-micro: 1rem !default;
$btn-padding-mobile-y: 0.3rem !default;
$btn-padding-mobile-x: 0.5rem !default;
$btn-with-icon-text-margin: 1.875rem !default;
$btn-with-icon-padding-x: 2.8125rem !default;
$btn-with-icon-rounded-size: 3.5rem !default;
$btn-with-icon-rounded-size-micro: 2.25rem !default;
$btn-with-icon-rounded-font-micro: 1.5rem !default;
$btn-with-icon-rounded-position-top: 0.4rem !default;
$btn-with-icon-rounded-position-left: 0.46rem !default;
$btn-with-icon-icon-top: 0.75rem !default;
$btn-with-icon-rounded-icon-left: 1.23rem !default;
$btn-with-icon-icon-size: 2rem !default;
$btn-group-button-padding-x: 1.53rem !default;
$btn-box-shadow: $greeny-box-shadow !default;
$btn-box-danger-shadow: $danger-box-shadow !default;
$btn-box-warning-shadow: $warning-box-shadow !default;
$btn-box-success-shadow: $success-box-shadow !default;
$btn-box-info-shadow: $info-box-shadow !default;
$btn-box-pale-shadow: 0px 4px 70px -16px $theme-pale !default;
$btn-box-dark-shadow: 0px 4px 70px -16px $almost-black !default;
$btn-border-radius: 1.875rem !default;
$btn-dark-color: $white !default;
$btn-dark-bg: $darkest-gray !default;
$btn-dark-border: $darkest-gray !default;
$btn-pale-bg: $theme-pale !default;
$btn-pale-color: $white !default;
$btn-dd-arrow-size: 1rem !default;
$btn-border: none !default;
$btn-secondary-theme-border: 2px solid $brand-primary !default;

//Vuesax
$--vs-background: 214, 241, 255 !important;
//Dropdowns
$dropdown-link-color: $white !default;
$dropdown-box-shadow: $greeny-box-shadow !default;
$dropdown-background: $darkest-gray !default;
$dropdown-link-color: $white !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $almost-black !default;
$dropdown-link-active-color: $white !default;
$dropdown-link-active-bg: $almost-black !default;
$dropdown-item-padding-x: 25px !default;
$dropdown-item-padding-y: 0 !default;
$dropdown-item-height: 40px !default;
$dropdown-menu-padding-y: 10px !default;
$dropdown-menu-padding-x: 0 !default;
$dropdown-min-width: 15rem !default;
$dropdown-simple-visible-items: 4 !default;
$dropdown-multi-visible-items: 4 !default;
$dropdown-show-b: 1.125rem !default;
//Modals
$modal-header-padding-x: $widget-padding !default;
$modal-header-padding-y: 0 !default;
$modal-header-height: $widget-header-height !default;
$modal-header-border: $widget-header-border !default;
$modal-content-border-width: 0 !default;
$modal-content-border-radius: 0 !default;
$modal-inner-padding-eveler: 25px !default;
$modal-footer-btns-padding-bottom: 20px !default;
$modal-footer-btns-margin-x: 10px !default;
$modal-md: 650px !default;
$modal-lg: 1250px !default;
//Meter
$top-right-wrapper-top: calc(
  #{$top-nav-height} + #{$content-wrap-pt} + #{$widget-header-height}
) !default;
$top-right-wrapper-mobile-top: calc(#{$top-mobile-nav-height} + 1rem) !default;
// $top-right-wrapper-mobile-top: calc(#{$top-mobile-nav-height} + #{$content-mobile-wrap-pt} + #{$widget-header-height}) !default;
//Forms
$form-group-mb: $font-size-base * 1.5 * 0.9;
$input-border-color: $lighter-gray !default;
$input-bg-disabled: $white !default;
$vuestic-switch-bg: $brand-primary !default;
$vuestic-switch-padding: 0.313rem 2.375rem !default;
$vuestic-switch-border-size: 0.125rem !default;
//Progress Bars
$progress-bar-value-font-size: 0.6875rem !default;
$progress-bar-circle-diameter: 3.125rem !default;
$progress-bar-circle-bw: 0.0678rem !default;
$progress-bar-circle-overlay-diameter: calc(
  #{$progress-bar-circle-diameter} - 2 *#{$progress-bar-circle-bw}
) !default;
$progress-bar-vertical-height: 5.75rem !default;
$progress-bar-width-thin: 0.125rem !default;
$progress-bar-width-basic: 0.5rem !default;
$progress-bar-width-thick: 1.5rem !default;
//Tables
$table-bg-accent: $white !default;
$table-border-width: 0 !default;
$table-border-color: #eceeef !default;
$striped-row-odd: $white !default;
$striped-row: $vue-lightest-blue !default;
$hovered-row: #efffd2 !default;
$state-success-bg: #c8fac6 !default;
$state-danger-bg: #ffcece !default;
$state-warning-bg: #fff1c8 !default;
$state-info-bg: #dcf1ff !default;
//Badges
$badge-padding-y: 0.28rem !default;
$badge-min-width: 5rem !default;
$badge-font-size: 0.7rem !default;
$badge-success-bg: $brand-success !default;
$badge-warning-bg: $theme-orange !default;
$badge-danger-bg: $theme-red !default;
$badge-info-bg: $theme-blue !default;
//Alerts
$alert-padding-x: 1.25rem !default !default;
$alert-padding-y: 0.75rem !default !default;
$alert-margin-bottom: $widget-mb !default;
$with-close-pr: 3.125rem !default;
$alert-border-radius: 0 !default;
$alert-border-width: 0 !default;
$alert-success-bg: $light-green !default;
$alert-success-text: $body-color !default;
$alert-success-border: transparent !default;
$alert-success-shadow: 0px 4px 9.6px 0.4px rgba(79, 206, 145, 0.5) !default;
$alert-info-bg: $light-blue !default;
$alert-info-text: $body-color !default;
$alert-info-border: transparent !default;
$alert-info-shadow: 0px 4px 9.6px 0.4px rgba(79, 142, 206, 0.5) !default;
$alert-warning-bg: $light-yellow !default;
$alert-warning-text: $body-color !default;
$alert-warning-border: transparent !default;
$alert-warning-shadow: 0px 4px 9.6px 0.4px rgba(206, 178, 79, 0.5) !default;
$alert-danger-bg: $light-pink !default;
$alert-danger-text: $body-color !default;
$alert-danger-border: transparent !default;
$alert-danger-shadow: 0px 4px 9.6px 0.4px rgba(206, 79, 79, 0.5) !default;
//Icons
$bootstrap-sass-asset-helper: false !default;
$icon-font-name: 'glyphicons-halflings-regular' !default;
$icon-font-svg-id: 'glyphicons_halflingsregular' !default;
$icon-font-path: './fonts/' !default;
//Tabs
$tab-content-pt: 3.125rem !default;
$tab-content-pb: 1.5rem !default;
//PreLoader
$vuestic-preloader-left: calc(50% - 140px / 2) !default;
$vuestic-preloader-top: calc(50% - 104px / 2) !default;
//Medium Editor
$medium-editor-button-size: $btn-padding-y * 2 + $font-size-base *
  $btn-line-height !default;
//tooltips
$tooltip-font-size: $font-size-base !default;
$tooltip-box-shadow: $greeny-box-shadow !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $darkest-gray !default;
$tooltip-border-radius: $font-size-base * 0.5 !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.5rem !default;
$tooltip-padding-x: 0.75rem !default;
$tooltip-margin: 0 !default;
$tooltip-line-height: 1.13 !default;
$tooltip-font-weight: 300 !default;
$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;
// Popovers
$popover-font-size: $font-size-base !default;
$popover-line-height: $tooltip-line-height !default;
$popover-box-shadow: $tooltip-box-shadow !default;
$popover-bg: $tooltip-bg !default;
$popover-max-width: 400px !default;
$popover-border-width: 0 !default;
$popover-border-color: transparent !default;
$popover-border-radius: $tooltip-border-radius !default;
$popover-box-shadow: $greeny-box-shadow !default;
$popover-opacity: $tooltip-opacity !default;
$popover-header-bg: $tooltip-bg !default;
$popover-header-color: $white !default;
$popover-header-padding-y: 0 !default;
$popover-header-padding-x: 0.75rem !default;
$popover-header-no-icon-padding-x: 0 !default;
$popover-header-font-weight: bold !default;
$popover-body-color: $white !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;
$popover-body-no-icon-padding-x: $popover-header-no-icon-padding-x !default;
$popover-icon-size: 1.35rem !default;
$popover-icon-color: $brand-primary !default;
$popover-arrow-width: 1rem !default;
$popover-arrow-height: 1rem !default;
$popover-arrow-color: transparent !default;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;
// Toasts
$toast-font-size: $popover-font-size !default;
$toast-line-height: $popover-line-height !default;
$toast-font-weight: normal !default;
$toast-min-height: 3.25rem !default;
$toast-box-shadow: $popover-box-shadow !default;
$toast-bg: $popover-bg !default;
$toast-border-radius: $popover-border-radius !default;
$toast-color: $popover-body-color !default;
$toast-opacity: $popover-opacity !default;
$toast-padding-x: 1.15rem !default;
$toast-padding-y: 0.5rem !default;
$toast-icon-size: $popover-icon-size !default;
$toast-icon-color: $popover-icon-color !default;
$toast-icon-ml: 0 !default;
$toast-icon-mr: 0.625rem !default;

//Checkbox and RadioButton
$checkbox-between-items-margin: 1rem !default;
$checkbox-between-label-margin: 2.35rem !default;
$checkbox-label-margin-top: 0.2rem !default;

// AuthLayout
$triangles-svg-width: 50% !default;
$triangles-svg-max-height: 40% !default;
$triangles-bottom: -4px !default;

//OptGroupt
$optgroup_background: rgba(51, 204, 0, 0.03);

// Tabs
$border-tabs-color-hover: #ffae0d;
$border-tabs-color: #3f3f3f;
$border-tabs: 1px solid $border-tabs-color;
$border-tabs-hover: 2px solid $border-tabs-color-hover;
