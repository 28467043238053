.spinner {
    margin-left: auto;
    margin-right: auto;

    &:after {
        background-color: $ev-red;
    }



}

.spinner--rotate-square-2::after {
    background-color: $ulysse-blue-dark !important;

}