@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.widget {
  background: white;
  box-shadow: 0px 4px 70px -18px #707070;
  margin-bottom: 1.25rem;
  padding: 0;
  border-radius: 5px;
  width: 100%; }
  .widget .widget-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    font-weight: bold;
    font-size: 1.25rem;
    width: 100%; }
    .widget .widget-header.widget-header-centered {
      text-align: center; }
  .widget .widget-body {
    padding: 1.5rem; }
