@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.antenna-fiche {
  flex: 2; }

.siege-fiche {
  flex: 1; }

.alert-dashboard {
  flex: 1; }

.activity-dashboard {
  flex: 1; }

.data-quality-dashboard {
  flex: 1; }

.housing-dashboard {
  flex: 1; }

.domiciliation-dashboard {
  flex: 1; }
