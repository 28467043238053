@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.layout {
  min-width: 100%; }
  .layout .panel-conatiner {
    background-color: #f5f1f5; }
    .layout .panel-conatiner .main-panel {
      margin-left: 260px; }
      .layout .panel-conatiner .main-panel.reduced {
        margin-left: 50px !important; }

.vs-sidebar-content .vs-sidebar__logo img {
  max-width: 200px !important;
  max-height: 100px !important; }
