@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.file-upload-list-item {
  min-height: 2.7rem;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 8px 14.72px 1.28px rgba(101, 169, 119, 0.3);
  margin: 0 1rem;
  padding: 0.1rem 0.1rem; }
  .file-upload-list-item + .file-upload-list-item {
    margin-top: 0.5rem; }
  .file-upload-list-item-name {
    margin: auto; }
  .file-upload-list-item-size {
    margin: auto;
    color: #919191; }
  .file-upload-list-item-date {
    margin: auto;
    color: #919191; }
  .file-upload-list-item-buttons {
    display: flex;
    justify-content: flex-end; }
  .file-upload-list-item--undo {
    background: none;
    box-shadow: none; }

@media (max-width: 1200px) {
  .file-upload-list-item-buttons {
    margin-top: 0.7rem;
    justify-content: flex-start; } }
