@mixin flex-center () {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin theme-button-variant($color, $background, $border, $shadow) {
  $hover-bg: lighten($background, 10%);
  $disabled-bg: darken($background, 15%);
  $active-bg: darken($background, 15%);
  $disabled-color: darken($background, 30%);

  background-color: $background;
  color: $color;
  box-shadow: $shadow;
  border: $border;

  &:hover{
    color: $color;
    background-color: $hover-bg;
  }

  &.active,
  &:active,
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    background-color: $active-bg;
    box-shadow: $shadow;
    color: $color;

    &:focus {
      box-shadow: $shadow;
    }
  }

  &[disabled], &[disabled]:hover {
    background-color: $disabled-bg;
    color: $disabled-color;
    opacity: 0.5;
  }
}

@mixin circle-progress-bar($progressColor, $size, $width, $startColor: $gray-lighter, $innerColor: $white) {


  $step: 1;
  $loops: round(100 / $step);
  $increment: 360 / $loops;
  $half: round($loops / 2);

  @for $i from 0 through $loops {
    &.progress-bar.value-#{$i*$step} {
      @if $i < $half {
        $nextdeg: 90deg + ( $increment * $i );
        background-image: linear-gradient(90deg, $startColor 50%, transparent 50%, transparent),
        linear-gradient($nextdeg, $progressColor 50%, $startColor 50%, $startColor);
      }
      @else {
        $nextdeg: -90deg + ( $increment * ( $i - $half ) );
        background-image: linear-gradient($nextdeg, $progressColor 50%, transparent 50%, transparent),
        linear-gradient(270deg, $progressColor 50%, $startColor 50%, $startColor);
      }
    }
  }
}

@mixin tabs-content {
  &:hover {
    cursor: pointer;
    background-color: $ulysse-blue;

    .tab {
      background-color: $ulysse-blue;
      color: $white;
      border-right: $border-tabs-hover;
      
      i { 
        color: $white; 
      }
    };

    &.selected {
      background-color: $ulysse-blue-dark;

  .tab {
    background-color: $ulysse-blue-dark;}
    }
  }
  .tab {
    min-width: 100px;
    padding: 10px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    border-right: $border-tabs;
    margin: 10px 0;

    .fa {
      margin: 0 8px;
    }
   
  }
}

@mixin tabs-content-hover {
  cursor: pointer;
  background-color: $ulysse-blue-dark;

  .tab {
    background-color: $ulysse-blue-dark;
    color: $white;
    border-right: $border-tabs-hover;

    i {
      color: $white;
    }
  } 
}
