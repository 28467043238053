@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.md-subheader {
  font-size: 1.2rem; }

.no-underline.md-field:before {
  visibility: hidden !important; }

.no-underline.md-field:after {
  visibility: hidden !important; }

.period {
  margin-top: 16px;
  margin-bottom: 16px; }
