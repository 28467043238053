@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-file-upload-list {
  padding-bottom: 2rem;
  margin: 0 -1rem; }

.vuestic-file-upload-gallery {
  padding: 0 0 1rem; }
