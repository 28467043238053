@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-modal {
  height: 0;
  width: 0; }
  .vuestic-modal .modal {
    z-index: 3;
    display: block; }
  .vuestic-modal .modal-dialog,
  .vuestic-modal .modal-backdrop {
    transition: all 0.5s ease;
    z-index: 2; }
  .vuestic-modal .modal-enter .modal-dialog,
  .vuestic-modal .modal-leave-active .modal-dialog {
    opacity: 0;
    transform: translateY(-30%); }
  .vuestic-modal .modal-enter .modal-backdrop,
  .vuestic-modal .modal-leave-active .modal-backdrop {
    opacity: 0; }
  .vuestic-modal .modal-backdrop {
    opacity: 0.5;
    min-width: 100%;
    min-height: 100%; }
  .vuestic-modal .modal-header {
    height: 55px;
    padding: 0 1.5625rem;
    border-bottom: 2px solid #f5f1f5;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center; }
  .vuestic-modal .close-modal {
    margin-left: 1rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    cursor: pointer; }
  .vuestic-modal .modal-content {
    border-radius: 0; }
  .vuestic-modal .modal-footer {
    justify-content: center;
    padding: 0 25px;
    padding-bottom: calc( 25px - 20px);
    flex-wrap: wrap; }
    .vuestic-modal .modal-footer .btn {
      margin: 0 10px 20px 10px; }
  .vuestic-modal .modal-dialog {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
