@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.tabs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 !important; }
  .tabs-wrapper .tabs-content {
    background-color: white;
    display: flex;
    align-items: center;
    margin: 5px 0 7px 0;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    min-height: 48px;
    border-radius: 5px; }
    .tabs-wrapper .tabs-content .content:hover {
      cursor: pointer;
      background-color: #66ccff; }
      .tabs-wrapper .tabs-content .content:hover .tab {
        background-color: #66ccff;
        color: #fff;
        border-right: 2px solid #ffae0d; }
        .tabs-wrapper .tabs-content .content:hover .tab i {
          color: #fff; }
      .tabs-wrapper .tabs-content .content:hover.selected {
        background-color: #2e517b; }
        .tabs-wrapper .tabs-content .content:hover.selected .tab {
          background-color: #2e517b; }
    .tabs-wrapper .tabs-content .content .tab {
      min-width: 100px;
      padding: 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      border-right: 1px solid #3f3f3f;
      margin: 10px 0; }
      .tabs-wrapper .tabs-content .content .tab .fa {
        margin: 0 8px; }
    .tabs-wrapper .tabs-content .selected {
      cursor: pointer;
      background-color: #2e517b; }
      .tabs-wrapper .tabs-content .selected .tab {
        background-color: #2e517b;
        color: #fff;
        border-right: 2px solid #ffae0d; }
        .tabs-wrapper .tabs-content .selected .tab i {
          color: #fff; }
