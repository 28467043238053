@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.infos {
  flex: 2; }

.widget {
  height: 100%;
  width: 100%; }

.info-wrapper {
  max-width: 250px; }
  .info-wrapper .address {
    justify-content: space-between; }

.horaires {
  flex: 1; }
