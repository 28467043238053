@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.file-upload-gallery-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 1rem; }
  .file-upload-gallery-item:hover .file-upload-gallery-item-overlay {
    display: flex; }
  .file-upload-gallery-item-overlay {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    padding: 0.5rem;
    background: rgba(51, 204, 0, 0.8);
    z-index: 1; }
  .file-upload-gallery-item-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: scale-down; }
  .file-upload-gallery-item-name {
    color: #34495e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .file-upload-gallery-item-size {
    color: #34495e; }
  .file-upload-gallery-item-button {
    margin-top: auto;
    text-align: left; }
  .file-upload-gallery-item-undo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.7rem 0 0; }
