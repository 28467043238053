$shadow-inset: inset 0 2px 2px 0 rgba(0, 0, 0, .14);
$shadow-0: 0 0 1px rgba(0, 0, 0, 0);
$shadow-2: 0 2px 2px 0 rgba(0, 0, 0, .14),
0 3px 1px -2px rgba(0, 0, 0, .2),
0 1px 5px 0 rgba(0, 0, 0, .12);
$shadow-3: 0 3px 4px 0 rgba(0, 0, 0, .14),
0 3px 3px -2px rgba(0, 0, 0, .2),
0 1px 8px 0 rgba(0, 0, 0, .12);
$shadow-4: 0 4px 5px 0 rgba(0, 0, 0, .14),
0 1px 10px 0 rgba(0, 0, 0, .12),
0 2px 4px -1px rgba(0, 0, 0, .2);
$shadow-6: 0 6px 10px 0 rgba(0, 0, 0, .14),
0 1px 18px 0 rgba(0, 0, 0, .12),
0 3px 5px -1px rgba(0, 0, 0, .2);
$shadow-8: 0 8px 10px 1px rgba(0, 0, 0, .14),
0 3px 14px 2px rgba(0, 0, 0, .12),
0 5px 5px -3px rgba(0, 0, 0, .2);
// Font Sizes
$mf-font-size: .9rem !default;
$mf-active-font-size: $mf-font-size * 0.85 !default;
$mf-active-top: -$mf-font-size * 0.6 !default;
// Margin & Paddings
$mf-gap: $mf-font-size * 1.5 !default;
$mf-min-height: 2.25rem;
$mf-spacer: $mf-font-size / 8 !default;
$mf-fieldset-margin: 0 0 $mf-gap * 2 !default;
$mf-checkbox-gap: $mf-font-size * 2 !default;
$mf-checkbox-top: 0 !default;
$mf-radio-margin: $mf-font-size / -4 !default;
$mf-icon-gutter: 1.375rem;
$mf-icon-top: 0.4375rem; // Colors
$mf-input-color: $body-color !default;
$mf-border-color: #999 !default;
$mf-label-color: lighten($mf-border-color, 10%) !default;
$mf-active-color: darken($mf-border-color, 10%) !default;
$mf-focus-color: $brand-primary !default;
$mf-error-color: $brand-danger !default;
$mf-focus-border-color: $charcoal !default;
// Animation
$mf-transition-speed: 0.28s !default;

.button-container {
  text-align: center;
}

.btn-container {
  display: flex;
  .btn-group,
  #subBtn {
    margin: auto;
  }
}

// The Styles for the form
fieldset {
  //margin: $mf-fieldset-margin;
  padding: 0;
  border: none;
}

// Mise en forme des titre de chaque option du bandeau
.banner-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  color: $banner-title-color;
  border-bottom: $vue-darkest-blue solid 2px;
  margin-bottom: 10px;
  width: 100%;
}

.form-inline {
  >.form-group,
  >.btn {
    display: inline-block;
    margin-bottom: 0;
  }
}

input.vuestic-date-picker {
  border:none;
  padding: $mf-spacer 2*$mf-spacer ($mf-spacer / 2);
  border-bottom: ($mf-font-size / 16) solid $mf-border-color;
  margin-left: ($mf-font-size * 0.2);
  margin-right: ($mf-font-size * 1.2);
  font-size: .9rem;
}

.abc-checkbox,
.abc-radio {
  display: flex !important;
  justify-content: flex-start;
  margin-left: 25px;
}

.form-help {
  margin-top: $mf-spacer;
  margin-left: $mf-spacer;
  color: $mf-label-color;
  font-size: $mf-active-font-size;
  .form-group & {
    position: absolute;
    width: 100%;
  }
  .checkbox & {
    position: relative;
    margin-bottom: $mf-font-size;
  }
}

.valid {
  .legend.legend,
  &.form-group .control-label.control-label {
    // Prevent !importantRule
    color: $mf-focus-color;
  }
  .valid-icon {
    display: inline-block;
    color: $mf-focus-color;
  }
}

.invalid{

    color:#919191;

}

.has-error {
  .legend.legend,
  &.form-group .control-label.control-label {
    // Prevent !importantRule
    color: $mf-error-color;
  }
  .error-icon {
    display: inline-block;
    color: $mf-error-color;
  }
  &.form-group {
    .icon-right {
      color: $mf-error-color;
    }
  }
  &.form-group,
  &.checkbox {
    .form-help,
    .helper {
      color: $mf-error-color;
    }
  }
  .bar {
    &::before {
      background: $mf-error-color;
      left: 0;
      width: 100%;
    }
  }
}

/* DatePicker */

// On ajuste quelques propriétés css pour que le datepicker s'affiche correctement
.bootstrap-datetimepicker-widget {
  .table-condensed {
    .glyphicon {
      color: $ev-red-dark !important;
    }
  }
  table thead th {
    color: black !important;
    text-transform: unset !important;
    border: unset;
  }
  .timepicker {
    // display: none;
    .btn {
      box-shadow: unset;
    }
  }
  &.dropdown-menu {
    background: white !important;
    border: $gray solid 1px;
    box-shadow: 0 4px 70px -18px $ev-gray;
  }
  .glyphicon {
    a {
      color: black;
    }
  }
}

/* Multiselect */

fieldset[disabled] .multiselect {
  pointer-events: none
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block
}

.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: $vue-green transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent
}

.multiselect__spinner:before {
  animation: a 2.4s cubic-bezier(.41, .26, .2, .62);
  animation-iteration-count: infinite
}

.multiselect__spinner:after {
  animation: a 2.4s cubic-bezier(.51, .09, .21, .8);
  animation-iteration-count: infinite
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity .4s ease-in-out;
  opacity: 1
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 14px;
  -ms-touch-action: manipulation;
  touch-action: manipulation
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e
}

.multiselect * {
  box-sizing: border-box
}

.multiselect:focus {
  outline: none
}

.multiselect--disabled {
  opacity: .6
}

.multiselect--active {
  z-index: 1
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.multiselect--active .multiselect__select {
  transform: rotate(180deg)
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 1px 0 0 5px;
  width: 100%;
  transition: border .1s ease;
  box-sizing: border-box;
  margin-bottom: 8px
}

.multiselect__tag~.multiselect__input,
.multiselect__tag~.multiselect__single {
  width: auto
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none
}

.multiselect__single {
  padding-left: 6px;
  margin-bottom: 8px
}

.multiselect__tags-wrap {
  display: inline
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: $vue-green;
  margin-bottom: 8px;
  white-space: nowrap
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all .2s ease;
  border-radius: 5px
}

.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff
}

.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8
}

.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer
}

.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform .2s ease
}

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  content: ""
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px
}

.multiselect--active .multiselect__placeholder {
  display: none
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
  -webkit-overflow-scrolling: touch
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8
}

.multiselect__content::webkit-scrollbar {
  display: none
}

.multiselect__element {
  display: block
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap
}

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px
}

.multiselect__option--highlight {
  background: $vue-green;
  outline: none;
  color: #fff
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: $vue-green;
  color: #fff
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff
}

.multiselect--disabled {
  background: #ededed;
  pointer-events: none
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: #ededed;
  color: #a6a6a6
}

.multiselect__option--disabled {
  cursor: text;
  pointer-events: none
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede !important
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all .15s ease
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0
}

.multiselect__strong {
  margin-bottom: 10px;
  display: inline-block
}

[dir=rtl] .multiselect {
  text-align: right
}

[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px
}

[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px
}

[dir=rtl] .multiselect__content {
  text-align: right
}

[dir=rtl] .multiselect__option:after {
  right: auto;
  left: 0
}

[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px
}

[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px
}

@keyframes a {
  0% {
    transform: rotate(0)
  }
  to {
    transform: rotate(2turn)
  }
}
