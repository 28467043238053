@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.file-upload-single-item {
  display: flex; }
  .file-upload-single-item-name {
    margin-right: 1rem; }
