@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.navbar {
  height: 75px;
  margin-left: 200px;
  background-color: #2e517b; }
  .navbar.reduced {
    margin-left: 50px; }
  .navbar .navbar-elements {
    color: aliceblue;
    width: 100%; }
    .navbar .navbar-elements .user-profile .alerts {
      cursor: pointer;
      position: relative; }
      .navbar .navbar-elements .user-profile .alerts ion-icon {
        font-size: 2rem; }
      .navbar .navbar-elements .user-profile .alerts .alerts-count {
        border-radius: 0.4rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        color: black;
        text-align: center;
        text-anchor: center;
        z-index: 2; }
      .navbar .navbar-elements .user-profile .alerts .unread-alerts {
        position: absolute;
        left: 0rem;
        top: 0.2rem;
        background-color: #f49e4c; }
      .navbar .navbar-elements .user-profile .alerts .unhandled-alerts {
        position: absolute;
        right: 0rem;
        top: 0.2rem;
        background-color: #f5ee9e; }
    .navbar .navbar-elements .user-profile .profile {
      cursor: pointer; }
      .navbar .navbar-elements .user-profile .profile ion-icon {
        font-size: 3rem; }
    .navbar .navbar-elements .user-profile .logout {
      cursor: pointer; }
      .navbar .navbar-elements .user-profile .logout ion-icon {
        font-size: 2rem; }
