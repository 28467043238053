@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-file-upload-container {
  position: relative; }

.vuestic-file-upload-field {
  padding: 0 0 1.3rem;
  overflow: hidden;
  display: inline-block;
  position: relative; }
  .vuestic-file-upload-field--dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
    padding: 0 2rem;
    transition: height 0.2s;
    overflow: visible;
    position: static; }
  .vuestic-file-upload-field-text {
    padding-right: 10px; }
  .vuestic-file-upload-field-input {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    font-size: 999px;
    min-height: 100%;
    min-width: 100%;
    color: transparent;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer; }

@media (max-width: 576px) {
  .vuestic-file-upload-field--dropzone {
    flex-direction: column;
    padding: 0; } }
